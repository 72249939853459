import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { finanzcheck } from '@finanzcheck/teal-mui-theme';
import { getScaledSizeOnCharacterCount } from './HeadlineExclusiveBadges';

const { colors } = finanzcheck;
const colorMap: { [x: string]: { text: string; shadow: string } } = {
  light: {
    text: colors.text.white,
    shadow: '0 0 8px rgba(0, 0, 0, 0.4)',
  },
  dark: {
    text: colors.text.blue,
    shadow: 'none',
  },
};

const HeadlineWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'headlineColor',
})<{ headlineColor: string }>(({ headlineColor }) => ({
  color: colorMap[headlineColor].text,
  textShadow: colorMap[headlineColor].shadow,
}));

interface HeadlineElementProps {
  charcount: number;
}

const Title = styled(Typography)<HeadlineElementProps>(
  ({ theme, charcount }) => ({
    fontSize: getScaledSizeOnCharacterCount(1.5, charcount, 'rem'),
    lineHeight: getScaledSizeOnCharacterCount(2, charcount, 'rem'),
    fontWeight: 'bold',
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      fontSize: getScaledSizeOnCharacterCount(2, charcount, 'rem'),
    },

    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
      lineHeight: getScaledSizeOnCharacterCount(56, charcount, 'px'),
    },

    [theme.breakpoints.up('lg')]: {
      fontSize: getScaledSizeOnCharacterCount(2.5, charcount, 'rem'),
    },
  }),
);

const Subtitle = styled(Typography)<HeadlineElementProps>(
  ({ theme, charcount }) => ({
    fontSize: getScaledSizeOnCharacterCount(1.125, charcount, 'rem'),
    lineHeight: 1.35,
    [theme.breakpoints.up('sm')]: {
      fontSize: getScaledSizeOnCharacterCount(1.5, charcount, 'rem'),
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: getScaledSizeOnCharacterCount(2, charcount, 'rem'),
    },
  }),
);

interface HeadlineProps {
  headline: string;
  subline?: string;
  headlineColor: 'light' | 'dark';
}

export const Headline: React.FC<HeadlineProps> = ({
  headline,
  subline,
  headlineColor,
}) => {
  return (
    <HeadlineWrapper headlineColor={headlineColor}>
      <Title charcount={headline.length}>{headline}</Title>
      {subline && <Subtitle charcount={subline.length}>{subline}</Subtitle>}
    </HeadlineWrapper>
  );
};
