import { styled } from '@mui/material/styles';
import Image from 'next/image';
import { useTheme, useMediaQuery, Container, Grid } from '@mui/material';

import { ICeStageFields } from 'types/generated/contentful';
import { BulletPoints } from './BulletPoints/BulletPoints';
import { Seals } from './Seals/Seals';
import { LoanSelector } from 'components/LoanSelector/LoanSelector';
import { Headline } from './Headline/Headline';
import { LegalText } from './LegalText/LegalText';

const StageWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: 0,
  },
}));

// This stage does not use the specified mobile image, so we use a hardcoded one
const IMAGE_SMALL_SCREEN = {
  url: 'https://images.ctfassets.net/f6c1mtmq4qrb/bC7a7rycZYnZYevJINdz2/92517e331a0e670bdf5fe7b6e838f9a9/header-202401-mobile.png?w=144&h=144&q=100&fm=webp',
  altText: 'Kreditvergleich bei FINANZCHECK.de',
};

const MobileImageWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
  bottom: '0px',
  float: 'right',

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const Background = styled('div', {
  shouldForwardProp: (prop) => prop !== 'desktopImageSrc',
})<{ desktopImageSrc?: string }>(({ theme, desktopImageSrc }) => ({
  width: '100%',
  height: '144px',
  background:
    'linear-gradient( 153deg, rgba(36, 150, 210, 1) 0%, rgba(11, 106, 170, 1) 36%, rgba(11, 62, 125, 1) 100% )',

  [theme.breakpoints.up('md')]: {
    height: 'auto',
    background: 'none',
    backgroundImage: `url(${desktopImageSrc})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}));

const StageFooter = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

const DesktopLoanSelectorWrapper = styled('div')(({ theme }) => ({
  display: 'none',
  maxWidth: '68%',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
  borderRadius: '4px 4px 0 0',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

const HeadlineWrapper = styled(Grid)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(2)} 0 ${theme.spacing(3)}`,
  },
}));

const DesktopContentWrapper = styled('div')(({ theme }) => ({
  display: 'none',
  position: 'relative',
  width: '100%',
  height: '100%',

  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

const BulletPointsWrapper = styled('div')({
  position: 'absolute',
  bottom: '4%',
  left: '8%',
});

const MobileLoanSelector = styled('div')(({ theme }) => ({
  display: 'block',
  padding: `${theme.spacing(2)} ${theme.spacing(3)} 0`,
  // @ts-ignore:next-line
  backgroundColor: theme.palette.background.blue050,

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export type StageProps = ICeStageFields;

export const Stage: React.FC<StageProps> = ({
  headline,
  headlineColor,
  subline,
  image,
  bulletPoints,
  seals,
}) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const headlineColorWithBreakpoint = isMdUp ? headlineColor : 'light';

  const bulletColor =
    headlineColorWithBreakpoint === 'light'
      ? theme.palette.background.default
      : theme.palette.primary.main;

  return (
    <StageWrapper>
      <Background desktopImageSrc={image.fields.file?.url?.toString()}>
        <Container disableGutters style={{ position: 'relative' }}>
          <MobileImageWrapper>
            <Image
              style={{ float: 'right' }}
              src={IMAGE_SMALL_SCREEN.url}
              alt={IMAGE_SMALL_SCREEN.altText}
              height={144}
              width={144}
            />
          </MobileImageWrapper>
          <Grid container spacing={0}>
            <HeadlineWrapper item xs={8} md={6}>
              <Headline
                headline={headline}
                subline={subline}
                headlineColor={headlineColorWithBreakpoint}
              />
            </HeadlineWrapper>
            <Grid item xs={0} sm={6}>
              &nbsp;
            </Grid>
            <Grid item sm={6} justifyContent={'center'} display={'flex'} xs={0}>
              <DesktopLoanSelectorWrapper>
                <LoanSelector indexOnBestOffer="1" />
              </DesktopLoanSelectorWrapper>
            </Grid>
            <Grid item sm={6}>
              <DesktopContentWrapper>
                <BulletPointsWrapper>
                  <BulletPoints
                    bulletPoints={bulletPoints}
                    textColor={bulletColor}
                  />
                </BulletPointsWrapper>
              </DesktopContentWrapper>
            </Grid>
          </Grid>
        </Container>
      </Background>
      <MobileLoanSelector>
        <LoanSelector indexOnBestOffer="1" />
      </MobileLoanSelector>
      <StageFooter>
        <Seals seals={seals} />
        <LegalText />
      </StageFooter>
    </StageWrapper>
  );
};
