import { getTwoThirdsLegalText } from 'lib/legaltext/legalTextHelper';
import { useBestOffer } from 'contexts/BestOfferContext';
import { useLoanSelection } from 'contexts/LoanSelectionContext';
import { VariantLegalText } from './LegalTextExclusiveBadges';

// Note: this is currently the largest contentful paint on the page,
// closely followed by the cookie banner text. Optimizing it will have no big
// impact on the overall performance of the page, unless we are also getting
// rid of the cookie banner, in which case we have the chance of moving the LCP
// to an earlier point in time.
// Adding a skeleton unfortunately does not help, as google detects that it is
// not the real text and ignores it for the LCP calculation.
export const LegalText = () => {
  const { loanSelection } = useLoanSelection();
  const { bestOffer } = useBestOffer();

  const text = getTwoThirdsLegalText(bestOffer, loanSelection);
  return (
    <VariantLegalText>
      {text && (
        <>
          <b>¹Beispielrechnung gemäß PAngV</b> {text}
        </>
      )}
    </VariantLegalText>
  );
};
